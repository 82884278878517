import React from "react"

import { StaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"

const Metas = () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <Helmet>
        <meta charSet="utf-8" />
        <title>{ data.site.siteMetadata.title }</title>
      </Helmet>
    )}
  />

)

const Layout = ({ children, data, ...props}) => (
  <>
    <Metas/>
    {children}
  </>
)

export default Layout
